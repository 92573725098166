import { Link } from "@remix-run/react"

interface ShareModalProps {
  message: string
  link: string
  shareType: string
  close: () => void
}

export const ShareModal = ({
  message,
  link,
  shareType,
  close,
}: ShareModalProps) => {
  const isBroadcast = shareType === "broadcast"
  const facebookLink = `https://www.facebook.com/dialog/share?app_id=144056502621245&display=popup&href=${link}&redirect_uri=${link}`
  const twitterMessage = isBroadcast
    ? `Listen to ‘${message}’ on @RYMRadio`
    : `Check out today’s resource offer from @RYMRadio`
  const twitterLink = `https://twitter.com/intent/tweet?text=${twitterMessage}&url=${link}`
  const emailMessage = isBroadcast
    ? `Listen to ‘${message}’ on Renewing Your Mind`
    : `Check out today’s resource offer from Renewing Your Mind`
  const emailLink = `mailto:?subject=${emailMessage}&body=${link}`

  return (
    <>
      <div id="share" className="modal" data-testid="share-modal">
        <div id="share-content" className="modal-content">
          <button
            id="share-close-btn"
            className="modal-close-btn"
            onClick={close}
          >
            &#xd7;
          </button>
          <h2>Share</h2>
          <nav id="share-to">
            <Link
              id="facebook-btn"
              data-icon="&#xe906;"
              to={facebookLink}
              target="_blank"
              title="Facebook"
              onClick={close}
              rel="noreferrer"
            >
              <span className="visually-hidden">Facebook link</span>
            </Link>
            <Link
              id="twitter-btn"
              data-icon="&#xe905;"
              to={twitterLink}
              target="_blank"
              title="Twitter"
              onClick={close}
              rel="noreferrer"
            >
              <span className="visually-hidden">twitter link</span>
            </Link>
            <Link
              id="email-btn"
              data-icon="&#xe90a;"
              to={emailLink}
              title="Email"
              onClick={close}
            >
              <span className="visually-hidden">email link</span>
            </Link>
          </nav>
        </div>
      </div>
      {/* eslint-disable jsx-a11y/no-static-element-interactions */}
      <div
        id="share-overlay"
        className="modal-overlay"
        onClick={close}
        onKeyUp={close}
      />
    </>
  )
}
