import { Link } from "@remix-run/react"

export function TopBanner() {
  return (
    <div id="top-banner" className="col-100">
      <Link
        className="col-100 copy"
        to="https://gift.renewingyourmind.org/1040/25-years?utm_source=renewing+your+mind&utm_medium=banner&utm_campaign=25+years"
        target="_blank"
        rel="noreferrer"
      >
        Listener supported for more than 25 years.{" "}
        <span className="ignore-override">
          Your gift today fuels gospel outreach tomorrow.
        </span>
      </Link>
    </div>
  )
}
