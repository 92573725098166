import { useState, useRef, useEffect } from "react"
import { Link } from "@remix-run/react"
import useEventListener from "../utils/useEventListener"

export interface EpisodePlayerProps {
  title: string
  mp3: string
  prevDayUrl: string | undefined
  nextDayUrl: string | undefined
}

const toHMS = (seconds: number) => {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor((seconds % 3600) % 60)
  const hms =
    (h > 0 ? h + ":" : "") +
    (m > 0 ? (h > 0 && m < 10 ? "0" : "") + m + ":" : "0:") +
    (s < 10 ? "0" : "") +
    s

  return hms
}

export const EpisodePlayer = ({
  title,
  mp3,
  prevDayUrl,
  nextDayUrl,
}: EpisodePlayerProps) => {
  const playerRef = useRef<HTMLAudioElement>(null) // The <audio> element
  const scrubberRef = useRef<HTMLDivElement>(null) // The scrubber element
  const knobRef = useRef<HTMLSpanElement>(null) // The knob element

  const [showingPlayer, setShowingPlayer] = useState(false) // Is the player visible or not?
  const [progress, setProgress] = useState({ hms: "00:00", fillPx: 0 }) // Play time remaining and scrubber progress
  const [buttonClass, setButtonClass] = useState("") // Controls the icon displayed by the play button (play, loading, pause)
  const [knobPosition, setKnobPosition] = useState(0) // Knob is only visible when mouse is over scrubber--this controls its position
  const [knobDragging, setKnobDragging] = useState(false) // Is the knob currently being dragged?

  const fillPx = () => {
    const currentTime = playerRef.current?.currentTime || 0.0
    const duration = playerRef.current?.duration || 1.0
    const fillMaxWidth = scrubberRef.current?.offsetWidth || 0.0
    const progressFraction = currentTime / duration

    return Math.floor(fillMaxWidth * progressFraction)
  }

  const knobPx = () => {
    const knobCenterPx = fillPx()
    const knobWidth = knobRef.current?.offsetWidth || 0

    return Math.floor(knobCenterPx - knobWidth / 2)
  }

  const togglePlaying = () => {
    if (playerRef.current) {
      showingPlayer ? playerRef.current.pause() : playerRef.current.play()
      setShowingPlayer(!showingPlayer)
    }
  }

  const playerTimeUpdate = () => {
    setProgress({
      hms: toHMS(
        (playerRef.current?.duration || 0.0) -
          (playerRef.current?.currentTime || 0.0),
      ),
      fillPx: fillPx(),
    })

    if (!knobDragging) {
      setKnobPosition(knobPx())
    }
  }

  const knobUpdate = (x: number) => {
    if (scrubberRef.current && playerRef.current) {
      const trackLeft = scrubberRef.current.offsetLeft
      const trackWidth = scrubberRef.current.offsetWidth

      if (x >= trackLeft && x <= trackLeft + trackWidth) {
        const fraction = (x - trackLeft) / trackWidth

        if (playerRef.current.duration) {
          playerRef.current.currentTime = fraction * playerRef.current.duration
          setKnobPosition(knobPx())
        }
      }
    }
  }

  const knobMouseUpdate = (
    e: React.MouseEvent<HTMLDivElement>,
    updatePosition: boolean,
  ) => {
    if (scrubberRef.current && (updatePosition || knobDragging)) {
      knobUpdate(e.clientX - scrubberRef.current.getBoundingClientRect().left)
    }
  }

  const knobTouchUpdate = (
    e: React.TouchEvent<HTMLDivElement>,
    updatePosition: boolean,
  ) => {
    if (scrubberRef.current && (updatePosition || knobDragging)) {
      knobUpdate(
        e.changedTouches[0].clientX -
          scrubberRef.current.getBoundingClientRect().left,
      )
    }
  }

  useEventListener("keydown", (e) => {
    switch (e.key) {
      case " ":
        e.preventDefault()
        togglePlaying()
        break
      case "ArrowLeft":
        if (playerRef.current) {
          playerRef.current.currentTime -= 30
        }

        break
      case "ArrowRight":
        if (playerRef.current) {
          playerRef.current.currentTime += 30
        }

        break
    }
  })

  useEffect(() => {
    // If the track being played changes (e.g. because we moved to a
    // different broadcast), reset everything
    setShowingPlayer(false)
    setProgress({ hms: "00:00", fillPx: 0 })
    setButtonClass("")
    setKnobPosition(0)
    setKnobDragging(false)
  }, [mp3])

  return (
    <div id="episode-player" className={`audio-holder ${buttonClass}`}>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <h1 id="episode-title" onClick={togglePlaying} onKeyUp={togglePlaying}>
        {title}
      </h1>
      {prevDayUrl ? (
        <Link
          to={prevDayUrl}
          className="left-btn"
          aria-label="Go to previous day"
        >
          <span className="visually-hidden">go back</span>
        </Link>
      ) : (
        ""
      )}
      {nextDayUrl ? (
        <Link to={nextDayUrl} className="right-btn" aria-label="Go to next day">
          <span className="visually-hidden">go forward</span>
        </Link>
      ) : (
        ""
      )}
      <button
        id="play-btn"
        className="audio-play-btn"
        name="Play"
        onClick={togglePlaying}
        aria-label="Play"
      ></button>
      {showingPlayer ? (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          ref={scrubberRef}
          className="audio-scrubber"
          onMouseDown={(e) => {
            knobMouseUpdate(e, true)
            setKnobDragging(true)
          }}
          onMouseMove={(e) => knobMouseUpdate(e, false)}
          onMouseUp={(e) => {
            setKnobDragging(false)
            knobMouseUpdate(e, false)
          }}
          onTouchStart={(e) => {
            knobTouchUpdate(e, true)
            setKnobDragging(true)
          }}
          onTouchMove={(e) => knobTouchUpdate(e, false)}
          onTouchEnd={(e) => {
            setKnobDragging(false)
            knobTouchUpdate(e, false)
          }}
        >
          <span
            className="audio-scrubber-fill"
            style={{ width: progress.fillPx }}
          ></span>
          <span
            ref={knobRef}
            className="audio-scrubber-knob"
            style={{ left: knobPosition }}
          ></span>
          <span className="audio-clock">{progress.hms}</span>
        </div>
      ) : (
        <div style={{ height: "40px" }} />
      )}
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        ref={playerRef}
        className="audio-player"
        src={mp3}
        preload="none"
        controls={true}
        onTimeUpdate={playerTimeUpdate}
        onCanPlay={() => {
          setButtonClass("")
          setShowingPlayer(false)
        }}
        onPlaying={() => {
          setButtonClass("playing")
          setShowingPlayer(true)
        }}
        onPause={() => {
          setButtonClass("")
          setShowingPlayer(false)
        }}
        onWaiting={() => {
          setButtonClass("loading")
          setShowingPlayer(true)
        }}
        onEnded={() => {
          setButtonClass("")
          setShowingPlayer(false)
        }}
      ></audio>
    </div>
  )
}
