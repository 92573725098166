import { useState } from "react"
import { useInterval } from "../utils/useInterval"
import type { Campaign } from "../utils/helpers"
import { Link } from "@remix-run/react"

const secondsPerMinute = 60
const secondsPerHour = secondsPerMinute * 60
const secondsPerDay = secondsPerHour * 24

const minZero = (n: number) => (n < 0 ? 0 : n)

const endTimeToSeconds = (now: number, endTime?: string) =>
  endTime ? minZero(Math.floor((Date.parse(endTime) - now) / 1000)) : 0

const secondsToDHMS = (totalSeconds: number) => {
  const days = minZero(Math.floor(totalSeconds / secondsPerDay))

  totalSeconds -= days * secondsPerDay
  const hours = minZero(Math.floor(totalSeconds / secondsPerHour))

  totalSeconds -= hours * secondsPerHour
  const minutes = minZero(Math.floor(totalSeconds / secondsPerMinute))

  totalSeconds -= minutes * secondsPerMinute
  const seconds = minZero(totalSeconds)

  return { totalSeconds, days, hours, minutes, seconds }
}

export interface OfferProps {
  campaign: Campaign
  host: string
  endTime: string | undefined
  serverNow: number
}

export const Offer = ({ campaign, host, endTime, serverNow }: OfferProps) => {
  const [times, setTimesInfo] = useState(
    secondsToDHMS(endTimeToSeconds(serverNow, endTime)),
  )

  useInterval(() => {
    setTimesInfo(secondsToDHMS(endTimeToSeconds(Date.now(), endTime)))
  }, 1000)

  if (times.totalSeconds) {
    return (
      <div id="offer-cta" className="col-30 call-to-action">
        {campaign.offer && (
          <div className="countdown-holder">
            <h2>Offer Ends In</h2>
            <p id="offer-countdown" className="counter">
              {times.days ? (
                <span className="days" style={{ display: "inline-block" }}>
                  {times.days}
                </span>
              ) : (
                ""
              )}
              {times.hours ? (
                <span className="hours" style={{ display: "inline-block" }}>
                  {times.hours}
                </span>
              ) : (
                ""
              )}
              {times.minutes ? (
                <span className="minutes" style={{ display: "inline-block" }}>
                  {times.minutes}
                </span>
              ) : (
                ""
              )}
              <span className="seconds">{times.seconds}</span>
            </p>
          </div>
        )}
        <Link
          to={`${host}/${campaign.id}/${campaign.slug}?utm_source=renewing+your+mind&amp;utm_medium=button&amp;utm_campaign=offer`}
          target="_blank"
          className="btn"
          rel="noreferrer"
        >
          {campaign?.offer?.call_to_action || campaign.call_to_action}
        </Link>
      </div>
    )
  } else {
    return <></>
  }
}
