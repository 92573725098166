import { useEffect, useRef } from "react"

type callbackType = () => void

export const useInterval = (callback: callbackType, delay: number) => {
  const savedCallback = useRef<callbackType>(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => savedCallback.current()
    const id = setInterval(tick, delay)

    return () => clearInterval(id)
  }, [delay])
}
